<template>
  <div id="content" class="choose-data">
    <b-container id="step-2" style="background:#000028;color:white;">
      <b-row class="mt-5 pt-5 mb-5">

        <b-col>
          <div class="headline">
            <h5>Datum wählen </h5>
            <h3>Für welches Datum möchten Sie die ausgewählten Services anfragen?</h3>
          </div>

        </b-col>
      </b-row>


      <b-row class=" mb-5 ">
        <b-col md>
          <h6><span class="icon-Calender"></span> Bitte wählen Sie das Start- und Enddatum <span
              v-b-tooltip.hover class="icon-Information float-right"
              title="Sie können das Datum für einzelne Serviceleistungen oder für das gesamte Leistungspaket auswählen"></span>
          </h6>
          <v-date-picker v-model="date.range" :attributes="attrs" :is-expanded="true" :is-range="true"
                         :min-date="new Date()"
                         @input="onDateRangeChange">
          </v-date-picker>
        </b-col>
        <b-col md>
          <small><a class="float-right mt-sm-0 mt-5" href="#" @click.prevent="selectAllItems">Alle
            auswählen</a></small>
          <h5 class="mt-sm-0 mt-5">Datum wählen für:</h5>
          <b-list-group class="mb-3">
            <b-list-group-item v-for="orderItem in orderItems" :key="orderItem._id"
                               :active="activeSelection.indexOf(orderItem._id) > -1"
                               class="flex-column align-items-start border bg-transparent"
                               href="#"
                               @click="toggleItem(orderItem._id)"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1">{{ orderItem.name }}</h6>
                <div class="active-icon-wrapper bg-primary">
                  <span v-if="activeSelection.indexOf(orderItem._id) > -1"
                        class="icon-Check text-secondary font-weight-bold"></span>
                  <span v-else class="icon-Check text-primary font-weight-bold"></span>
                </div>
              </div>
              <small>

              <span v-if="orderItem.additionalServiceInformation.maintenanceLevel !== ''">
                  Instandhaltungsstufe: {{ orderItem.additionalServiceInformation.maintenanceLevel }}<br/>
                </span>
                <span v-if="orderItem.additionalServiceInformation.miscPre !== ''">
                 Sonstiges: {{ orderItem.additionalServiceInformation.miscPre }}<br/>
                </span>
                <span v-if="orderItem.additionalServiceInformation.miscCor !== ''">
                 Leistung: {{ orderItem.additionalServiceInformation.miscCor }}<br/>
                </span>
              </small>
              <small v-if="orderItem.date !== ''">
                <span v-if="orderItem.date.start">
                Datum: {{ orderItem.date.start|moment('DD.MM.YYYY') }} -
                {{ orderItem.date.end|moment('DD.MM.YYYY') }}</span>
                <span v-else>df
                  {{ orderItem.date }}
                </span>
              </small>
              <small v-else-if="orderItem.proposeDate">
                Terminvorschlag erwünscht
              </small>
              <small v-else>Bitte Datum wählen</small>

            </b-list-group-item>
          </b-list-group>

          <p class="mt-sm-5">Zusätzlicher Kommentar</p>
          <b-form-textarea
              id="textarea"
              v-model="additionalComment"
              class="w-100"
              max-rows="6"
              placeholder="Zusätzlicher Kommentar"
              rows="3"
              style="width:100%"
          ></b-form-textarea>
          <div class=" mb-5 mt-5">
            <b-form-checkbox
                id="proposeDate"
                v-model="proposeDate"
                name="proposeDate"
                @click="setDateToProposal"
            >
              Ich möchte keinen Termin wählen. Siemens wird mir einen Termin vorschlagen.
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="clearfix">
      <b-button class="float-left" variant="outline-primary" @click="$router.push('/')">Zurück</b-button>
      <span id="disabled-wrapper" class="d-inline-block float-right" tabindex="0">
      <b-button
          :disabled="!datesSet"
          class="float-right"
          variant="primary"
          @click="enterAddress"
      >
        Weiter
      </b-button>
      </span>
      <b-tooltip :disabled="datesSet" target="disabled-wrapper">
        Bitte geben Sie für jeden Service ein Datum an
      </b-tooltip>
    </b-container>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex"

export default {
  name: 'ChooseDate',
  data: function () {
    return {
      activeSelection: [],
      proposeDate: false,
      date: {
        range: "",
      },
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ],
    }
  },
  mounted() {
    this.activeSelection = this.orderItems.map(orderItem => orderItem._id)
  },
  methods: {
    setDateToProposal(value) {
      this.orderItems.filter(orderItem => this.activeSelection.indexOf(orderItem._id) > -1).forEach(orderItem => {
        this.setDateToProposalForItem({id: orderItem._id, value: value})
      });
    },
    ...mapMutations([
      "setDateForItem",
      "setDateToProposalForItem"
    ]),
    enterAddress: function () {
      this.$router.push("/enter-address")
    },
    unselectItem: function (id) {
      this.activeSelection.splice(this.activeSelection.indexOf(id), 1);
    },
    selectItem: function (id) {
      if (this.activeSelection.indexOf(id) === -1)
        this.activeSelection.push(id);
    },
    selectAllItems() {
      this.orderItems.forEach(orderItem => {
        this.selectItem(orderItem._id)
      })
      this.date.range = ""
    },
    toggleItem(id) {
      this.activeSelection = []
      this.proposeDate = false
      this.date.range = this.orderItems.filter(orderItem => orderItem._id === id)[0].date
      this.selectItem(id)
      // this.date.range = ""
    },
    onDateRangeChange() {
      this.orderItems.filter(orderItem => this.activeSelection.indexOf(orderItem._id) > -1).forEach(orderItem => {
        this.setDateForItem({id: orderItem._id, dateRange: this.date.range})
      });
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'orderItems',
      'datesSet'
    ]),
    additionalComment: {
      get() {
        return this.$store.state.cart.comment
      },
      set(value) {
        return this.$store.commit("setCartComment", value)
      }
    },
  },
  watch: {
    proposeDate(val) {
      if (this.activeSelection.length > 0) {
        this.date.range = ""
        this.setDateToProposal(val)
      }
    }
  }
}
</script>
<style lang="scss">


.active-icon-wrapper {
  line-height: 1;
  height: auto;
  background: green;
  padding: 3px;
}

.vc-highlight {
  width: 50px !important;
  height: 50px !important;

  &.vc-highlight-base-middle {
    width: 100% !important;
  }
}
</style>
